import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "./hoc/Layout/Layout";
// import Logout from "./containers/Auth/Logout/Logout";
import Aux1 from "./hoc/Aux1/Aux1";
import * as actions from "./store/actions/index";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import Spinner from "./components/UI/Spinner/Spinner";
// import "react-multi-carousel/lib/styles.css";
// import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/css/common.scss";
import "./styles/css/responsive.css";
import "./App.css";
// import "./AppEdit.css";
import "./styles/css/responsive.css";
import ReactGA from "react-ga4";
import packageJson from "../package.json";
import { ReactLenis, useLenis } from 'lenis/react'
global.appVersion = packageJson.version;

const Logout = lazy(() => import("./containers/Auth/Logout/Logout"));
const Landingpage = lazy(() => import("./components/Landingpage/Landingpage"));
const Room = lazy(() => import("./components/Room/Room"));
const Explore = lazy(() => import("./components/Explore/Explore"));
const About = lazy(() => import("./components/About/About"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const Launch = lazy(() => import("./components/Launch/Launch"));
const Login = lazy(() => import("./components/Launch/Login"));
const Booking = lazy(() => import("./components/Booking/Booking"));
const Favourites = lazy(() => import("./components/Favourites/Favourites"));
const Roomtype = lazy(() => import("./components/Roomtype/Roomtype"));
const Profile = lazy(() => import("./components/Profile/Profile"));
const Receipt = lazy(() => import("./components/Receipt/Receipt"));
const TRACKING_ID = "";
// ReactGA.initialize(TRACKING_ID);
const windowUrl = window.location.hash;
let paramString = windowUrl.split("?")[1];

if (paramString !== undefined) {
 var queryParams = paramString.split("&").reduce(function (q, query) {
    // var chunks = query.split("=");
    // var key = chunks[0];
    // if (key === "dt") {
    var data = paramString.substring(3);
      var value = decodeURIComponent(data);
      value = isNaN(Number(value)) ? value : Number(value);
      if (value !== undefined) localStorage.setItem("itstoken", value);
    // }

    return value;
  }, {});
}

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
    if (localStorage.itstoken !== "" && localStorage.itstoken !== undefined) {
      this.props.onOneLoginSignup();
    }
  }

  render() {
    let routes = (
      <Suspense fallback={<Spinner></Spinner>}>
        <Switch>
          <Route path="/" exact component={paramString !== undefined? Landingpage:Login} />
          {/* //uncomment on prod
          <Route path="/home" exact component={Landingpage} />  */}
          {/* <Route path="/info" component={Landingpage} /> */}
          <Route path="/login" exact component={Login} />
          {/* <Route path="/events" component={events} /> */}
        </Switch>
      </Suspense>
    );

    if (this.props.isAuthenticated) {
      localStorage.removeItem("itstoken");
      routes = (
        <Suspense
          fallback={
            // <div className="loader h-100">
            //   <div className="d-flex justify-content-center align-items-center h-100">
            //     <div className="spinner-border" role="status">
            //       <span className="sr-only"></span>
            //     </div>
            //   </div>
            // </div>
            <Spinner></Spinner>
          }
        >
          <Switch>
            <Route path="/" exact component={Launch} />
            <Route path="/home" exact component={Landingpage} />
            {/* <Route path="/info" component={Info} /> */}
            <Route path="/room" component={Room} />
            <Route path="/explore" component={Explore} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/booking" component={Booking} />
            <Route path="/favourites" component={Favourites} />
            <Route path="/roomtype" component={Roomtype} />
            <Route path="/profile" component={Profile} />
            {/* <Route path="/receipt" component={Receipt} /> */}
            {/* <Route path="/events" component={events} /> */}
            {/* <Route path="/hub" component={Offering} /> */}
            {/* {JSON.parse(localStorage.getItem("locations")) != null &&
            JSON.parse(localStorage.getItem("locations")).length > 0
              ? JSON.parse(localStorage.getItem("locations")).map((items) => (
                  <Route
                    path={items.url}
                    render={() => (
                      <Locationpage id={items.mazarid} name={items.mazarname} />
                    )}
                  />
                ))
              : null} */}

            <Route path="/logout" component={Logout} />
            <Route path="/home" component={Launch} />
            <Route
              path="**"
              render={() => (
                <article>
                  <h1 className="text-danger">No Route Configured!</h1>
                  <h4 className="text-danger">
                    Please check your Route Configuration
                  </h4>
                </article>
              )}
            />
          </Switch>
        </Suspense>
      );
    }

    return (
      <Aux1>
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={
            <Spinner></Spinner>
            // <div className="loader h-100">
            //   <div className="d-flex justify-content-center align-items-center h-100">
            //     <div className="spinner-border" role="status">
            //       <span className="sr-only"></span>
            //     </div>
            //   </div>
            // </div>
          } //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          <div className="App" >
          <ReactLenis root >
              <Layout>{routes}</Layout>
          </ReactLenis>
            
          </div>
        </CacheBuster>
      </Aux1>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onOneLoginSignup: () => dispatch(actions.authCheckOneLogin()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
