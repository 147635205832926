import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Footer.scss";
let path = "https://alraihaan.mazaraat.org/images";
// fetch("./path.json")
//   .then(function (res) {
//     return res.json();
//   })
//   .then(function (data) {
//     // store Data in State Data Variable
//     path = data.prod;
//   })
//   .catch(function (err) {
//     console.log(err, " error");
//   });

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privacypolicy: false,
      refundpolicy: false,
      tncpolicy: false,
      faq: false,

      //   isPrintOpen: false,
    };
  }

  closeModal = (name) => {
    this.setState({ [`${name}`]: false });
    // body.removeAttribute("data-lenis-prevent", true);
  };

  render() {
    return (
      <footer>
        <Container fluid="lg">
          <Row className="flex-row-reverse">
            <Col xs={12} md={7}>
              <Row>
                <Col xs={6} md={5}>
                  <h2>Quick links</h2>
                  <Nav className="flex-column">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#room">Rooms</Nav.Link>
                    {/* <Nav.Link href="#explore">Explore</Nav.Link> */}
                    <Nav.Link href="#about">About</Nav.Link>
                    <Nav.Link href="#contact">Contact</Nav.Link>
                  </Nav>
                </Col>
                <Col xs={6} md={5}>
                  <h2>Company</h2>
                  <Nav className="flex-column">
                    <Nav.Link
                      href="#"
                      onClick={() => this.setState({ privacypolicy: true })}
                    >
                      Privacy policy
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => this.setState({ refundpolicy: true })}
                    >
                      Refund policy
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => this.setState({ tncpolicy: true })}
                    >
                      Terms & Conditions
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => this.setState({ faq: true })}
                    >
                      F.A.Q
                    </Nav.Link>
                  </Nav>
                </Col>
              </Row>
              <br></br>
              <Row><h2>Legal entity - FAYZ E TAHERI PRIVATE LTD</h2></Row>
            </Col>
            <Col md={5}>
              <Navbar.Brand href="#home"></Navbar.Brand>
              {/* <p>
                (Text to be provided)
              </p> */}
            </Col>

            {/* <Col md={2}><h2>Social media</h2>
              <Nav className="flex-column">
                <Nav.Link href="#">Facebook</Nav.Link>
                <Nav.Link href="#">Twitter</Nav.Link>
                <Nav.Link href="#">Instagram</Nav.Link>
                <Nav.Link href="#">Linkedin</Nav.Link>
              </Nav></Col>
            <Col md={3}><h2>Newsletter</h2>
              <p>Kindly subscribe to our newsletter to get latest deals on our rooms and vacation discount.</p>
              <div className="subscribeSection">
                <Form.Control
                  placeholder="Enter your email"
                  aria-label="Enter your email"
                  aria-describedby="basic-addon2"
                />
                <Button variant="primary" id="button-addon2">
                  Subscribe
                </Button>
              </div>
            </Col> */}
          </Row>
        </Container>
        <div className="footnote d-flex justify-content-center">
          <div className="text-center">Copyright @2024 Al Raihaan All Rights Reserved.
            <br></br>
            Version - {global.appVersion}
          </div>
          {/* <span>Version - {global.appVersion}</span> */}
        </div>
        
        <Modal
          show={this.state.privacypolicy}
          onHide={() => this.closeModal("privacypolicy")}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // scrollable
          className="policymodal"
          data-lenis-prevent="true"
          // onShow={this.stopScroll}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Privacy Policy
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Control as="textarea" value={this.state.testimony} /> */}
            <iframe
              style={{ width: "100%", height: "99%" }}
              src={`${path}/${"policy"}/${"privacypolicy.pdf"}`}
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal("privacypolicy")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.refundpolicy}
          onHide={() => this.closeModal("refundpolicy")}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // scrollable
          className="policymodal"
          data-lenis-prevent="true"
          // onShow={this.stopScroll}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Refund Policy
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Control as="textarea" value={this.state.testimony} /> */}
            <iframe
              style={{ width: "100%", height: "99%" }}
              src={`${path}/${"policy"}/${"refundpolicy.pdf"}`}
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal("refundpolicy")}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.tncpolicy}
          onHide={() => this.closeModal("tncpolicy")}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // scrollable
          className="policymodal"
          data-lenis-prevent="true"
          // onShow={this.stopScroll}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Terms & Conditions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Control as="textarea" value={this.state.testimony} /> */}
            <iframe
              style={{ width: "100%", height: "99%" }}
              src={`${path}/${"policy"}/${"tncpolicy.pdf"}`}
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal("tncpolicy")}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.faq}
          onHide={() => this.closeModal("faq")}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // scrollable
          className="policymodal"
          data-lenis-prevent="true"
          // onShow={this.stopScroll}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">F.A.Q</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Control as="textarea" value={this.state.testimony} /> */}
            <iframe
              style={{ width: "100%", height: "99%" }}
              src={`${path}/${"policy"}/${"faq.pdf"}`}
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.closeModal("faq")}>Close</Button>
          </Modal.Footer>
        </Modal>
      </footer>
    );
  }
}

export default Footer;
